import React from "react";

function Home() {
  const role = localStorage.getItem('role');  // Get role from localStorage
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <div>
      <h1>Welcome to Vanta Tracker!</h1>
      <p>Welcome to the home page 2!</p>
      {isAuthenticated && <p>Your role is: {role}</p>}  {/* Display role if authenticated */}
    </div>
  );
}

export default Home;
