import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import LoginForm from "../components/LoginForm";

function LoginForm({ setIsAuthenticated, setToken, API_URL }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/auth/login`,{
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email: username, password})
      });

      if (response.ok){
        const data = await response.json();
        localStorage.setItem('token', data.access_token);  // Store token
        localStorage.setItem('role', data.user.role);
        setToken(data.access_token);
        setIsAuthenticated(true);
        navigate('/welcome');
      }else {
        setErrorMessage('Login failed');
      }

    }catch(error){
      setErrorMessage('Error during login');
    }
  };


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Username:</label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        <label htmlFor="password">Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Login</button>
      </form>
      {errorMessage && <p>{errorMessage}</p>} {/* Display error message */}
    </div>
  );
}

export default LoginForm;
