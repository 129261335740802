import React from "react";

function UserList({ users }) {
  return (
    <div>
      <h1>Users</h1>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            {user.user} - {user.email} - {user.role}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UserList;
