import React, { useState, useEffect } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import AddUsers from './pages/AddUsers';
import WelcomePage from './pages/WelcomePage'
import Navbar from './components/Navbar';
import './styles/global.css';

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false); // Set initial state to false
  const [token, setToken] = useState('');
  const [users, setUsers] = useState([]);

  const API_URL = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}` || '';
  // const API_URL = `${process.env.REACT_APP_API_URL}/api/v1` || '';

  console.log(API_URL);
  console.log(`api-url initial ${API_URL}`)
  //check if token is in local storage on component mount
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setIsAuthenticated(true);
      setToken(savedToken);
    }
  }, []);

  // Fetch user data if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetch(`${API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUsers(data);
        });
    }
  }, [isAuthenticated, token]);

  // useEffect(() => {
  //   // Fetch initial users list on component mount
  //   if (isAuthenticated){
  //     fetch(`${API_URL}/users`, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       },
  //     })
  //       .then(res => res.json())
  //       .then((data) => {
  //         setUsers(data);
  //       });
  //   }
  // }, [isAuthenticated, token]);

  const handleAddUser = async (newUser) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newUser),
    });
    if (!response.ok){
      const errorData = await response.json();
      return Promise.reject(errorData.message);
      // throw new Error('Failed to add user');
    }
    const data = await response.json();
    setUsers([...users, data]);
    return response;
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setToken('');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
  }

  const isAdmin = localStorage.getItem('role') === 'adm1n';

  // Ensure the role is stored correctly after logging in
  useEffect(() => {
    if (isAuthenticated) {
      const role = localStorage.getItem('role');
      if (!role) {
        console.warn('User role not found in local storage');
      }
    }
  }, [isAuthenticated]);

  return (
    <div>
      <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout}/>
      <Routes>
        <Route path="/"
          element={<Home />} />
        <Route path="/login"
          element={
            <Login
              setIsAuthenticated={setIsAuthenticated}
              setToken={setToken}
              API_URL={API_URL}
            />
          }
        />
        {isAuthenticated && isAdmin &&
          <Route path="/add-users"
            element={
              <AddUsers
                users={users}
                handleAddUser={handleAddUser}
              />
            }
          />
        }
        <Route path="/welcome" element={<WelcomePage />} /> {/* New page after login */}
      </Routes>
    </div>
  );
}

export default App;
