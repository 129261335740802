import React from 'react';

function WelcomePage() {
  const role = localStorage.getItem('role');

  return (
    <div>
      <h1>Welcome to the application!</h1>
      <p>You have successfully logged in as {role}.</p>
    </div>
  );
}

export default WelcomePage;
