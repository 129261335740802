import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Navbar({ isAuthenticated, handleLogout }) {
  const navigate = useNavigate();
  const role = localStorage.getItem('role');

  const handleSignOut = () => {
    handleLogout();
    navigate('/')
  }
  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {!isAuthenticated && (
          <li>
            <Link to="/login">Login</Link>
          </li>
        )}
        {isAuthenticated && (
          <>
            {role === 'adm1n' && (
              <li>
                <Link to="/add-users">Add Users</Link>
              </li>
            )}
            <li>
              <button onClick={handleSignOut}>
                Sign Out
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
